/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
// import dashboard from './dashboard'

import universalPayments from './universal-payments'
import reports from './admin-settings'
import wallet from './wallet'
import subscription from './subscription'
import sellerSettings from './seller-settings'
import adminSettings from './admin-settings'


// Array of sections
export default [...universalPayments, ...adminSettings, ...sellerSettings]
