export default [
    {
      header: 'Admin Settings',
    },
    {
      title: 'Ads Manager',
      icon: 'PlayCircleIcon',
      children: [
        {
            title: 'Add Ads',
            route: 'add-ads',
        },
        // {
        //     title: 'Update Ads',
        //     route: 'Update Ads',
        // },
    ]
    },
  ]