export default [
    {
      header: 'Sellers',
    },
    {
      title: 'Seller Settings',
      icon: 'UserIcon',
      children: [
            {
                title: 'Approve Accounts',
                route: 'approve-accounts',
            },
            {
              title: 'Billing',
              route: 'seller-billing',
            },
            {
              title: 'Manual Topup',
              route: 'manual-topup',
            },
            {
              title: 'Assign Packages',
              route: 'assign-packages',
            },
            {
              title: 'Refund Charges',
              route: 'refund-charges',
            },
            {
              title: 'Customer Intimations Events',
              route: 'seller-customer-intimation-events',
            },
            {
              title: 'Universal Courier Accounts Approval',
              route: 'universal-courier-accounts-approval',
            },
        ],
    },

]
  