export default [
    {
      header: 'Payments',
    },
    {
      title: 'Universal Payments',
      icon: 'PieChartIcon',
      children: [
            // {
            //     title: 'Courier Payments',
            //     route: 'universal-courier-payments',
            // },
            // {
            //     title: 'Courier Aging',
            //     route: 'courier-receivables-aging',
            // },
            // {
            //     title: 'Upload Charges',
            //     route: 'upload-courier-charges',
            // },
            // {
            //     title: 'Discrepancy Report',
            //     route: 'discrepancy-report',
            // },
            // {
            //     title: 'Seller Payments',
            //     route: 'seller-payments',
            // },
            {
                title: 'Non-Reconciled Payments',
                route: 'courier-invoices',
                icon: 'FileTextIcon',
            },
            {
                title: 'Reconciled Payments',
                route: 'reconciled-payments',
                icon: 'FileTextIcon',
            },
            {
                title: 'Settle Payment Invoices',
                route: 'seller-invoices',
                icon: 'FileTextIcon',
            },
        ],
    },

]
  